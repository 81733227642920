import { makeStyles } from "@material-ui/core/styles";

import { theme } from "../../theme";

export const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 345,
    margin: "1rem auto",
    [theme.breakpoints.up("tablet")]: {
      maxWidth: 285,
    },
    [theme.breakpoints.up("desktop")]: {
      maxWidth: 345,
    },
  },
  media: {
    height: 140,
  },
  gridBody: {
    padding: "0.5rem",
    [theme.breakpoints.up("laptop")]: {
      padding: "1rem",
    },
    [theme.breakpoints.up("desktop")]: {
      padding: "2rem 5rem 5rem 5rem",
    },
  },
  tile: {
    padding: "0 0.5rem",
  },
  link:{
    textDecoration:"none",
    color:theme.palette.primary.main,
  }
}));
