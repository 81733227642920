export const services = [
  {
    name: "Domestic Cleaning",
    fileName: "fridge_clean.jpeg",
    link: "/services/domestic-cleaning",
  },
  {
    name: "Deep Cleaning",
    fileName: "people_cleaning_room.png",
    link: "/services/deep-cleaning",
  },
  {
    name: "House Keeping & Companionship",
    fileName: "bed_tidying.png",
    link: "/services/house-keeping",
  },
  {
    name: "Gardening",
    fileName: "man_planting_plant.png",
    link: "/services/gardening",
  },
  {
    name: "Concierge Services",
    fileName: "home_delivery.png",
    link: "/services/concierge-services",
  },
  {
    name: "Office Cleaning",
    fileName: "office.jpg",
    link: "/services/office-cleaning",
  },
];
