import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Img from "gatsby-image";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Carousel from "react-material-ui-carousel";
import { Link } from "gatsby";

import { services } from "./services";

import { useStyles } from "./ServicesGrid.styling";

const ServicesGrid = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const data = useStaticQuery(graphql`
    query ImageQuery {
      allFile(
        filter: {
          relativeDirectory: { eq: "service-grid" }
          extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    }
  `);
  return (
    <section style={{ padding: desktop ? "3rem 0" : "2rem 0.5rem" }}>
      {desktop && (
        <>
          <Typography
            variant="h2"
            gutterBottom
            style={{ padding: desktop ? "0 5rem" : "0 0.5rem" }}
          >
            Our services
          </Typography>
          <GridList
            cellHeight="auto"
            classes={{ root: classes.gridBody }}
            cols={3}
          >
            {services.map((item) => (
              <GridListTile key={item.name} classes={{ root: classes.tile }}>
                <Card className={classes.card}>
                  {data.allFile.edges.map(
                    (image) =>
                      image.node.childImageSharp.fluid.originalName ===
                        item.fileName && (
                        <Img
                          key={item.name}
                          className={classes.media}
                          fluid={image.node.childImageSharp.fluid}
                          alt={item.name}
                        />
                      )
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" noWrap>
                      {item.name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      <Link className={classes.link} to={item.link}>
                        Learn More
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </GridListTile>
            ))}
          </GridList>
        </>
      )}
      {!desktop && (
        <>
          <Typography variant="h2" gutterBottom>
            Our services
          </Typography>
          <Carousel navButtonsAlwaysVisible autoPlay="false" animation="slide">
            {services.map((item) => (
              <Card key={item.name} className={classes.card}>
                {data.allFile.edges.map(
                  (image) =>
                    image.node.childImageSharp.fluid.originalName ===
                      item.fileName && (
                      <Img
                        key={item.name}
                        className={classes.media}
                        fluid={image.node.childImageSharp.fluid}
                        alt={item.name}
                      />
                    )
                )}
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    {item.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    <Link className={classes.link} to={item.link}>
                      Learn More
                    </Link>
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Carousel>
        </>
      )}
    </section>
  );
};

export default ServicesGrid;
