import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin:"auto"
      },
      media: {
        height: 140,
      },
      icon:{
        width:"4rem",
        height:"4rem",
      },
      gridBody:{
        backgroundColor: theme.palette.primary.main,
        padding: "2rem 0.5rem",
        [theme.breakpoints.up("tablet")]: {
          padding: "5rem",
        },
      },
      mobileInfoGrid:{
        backgroundColor: theme.palette.primary.main,
      },
      mobileCardContent:{
        width:"65%",
        margin:"0 auto"
      },

}));
