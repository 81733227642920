import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    title: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
      link:{
        textDecoration:"none",
        color:"inherit"
      },
      bannerButtonMobile:{
        margin:"2.5vh 0 2.5vh 1rem"
      },
      bannerButton:{
        margin:"0 0 0 5%"
      }
}))