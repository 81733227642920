import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Carousel from "react-material-ui-carousel";

import { useStyles } from "./InfoGrid.styling";
import { SvgIcon } from "@material-ui/core";

import CalendarIcon from "../../assests/svg/calendar.svg";
import CleanerStaffIcon from "../../assests/svg/cleaners.svg";
import VacuumIcon from "../../assests/svg/vacuum.svg";
import HandClothIcon from "../../assests/svg/hand_cloth.svg";

import { gridData } from "./data";

const InfoGrid = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const Icons = {
    CalendarIcon,
    CleanerStaffIcon,
    VacuumIcon,
    HandClothIcon,
  };

  return (
    <section className={classes.gridBody}>
    <Typography variant="h2" gutterBottom>Why choose us?</Typography>
      {desktop && (
        <GridList
          cellHeight="auto"
          className={classes.gridList}
          cols={4}
          spacing={4}
        >
          {gridData.map((item) => (
            <GridListTile key={item.name} >
              <Typography align={"center"}>
                <SvgIcon className={classes.icon}>
                  {React.createElement(Icons[item.fileName])}
                </SvgIcon>
              </Typography>
              <CardContent>
                <Typography align={"center"} gutterBottom variant="h5" component="h2">
                  {item.name}
                </Typography>
                <Typography gutterBottom variant="body" component="p">
                  {item.description}
                </Typography>
              </CardContent>
            </GridListTile>
          ))}
        </GridList>
      )}
      {!desktop && (
        <Carousel navButtonsAlwaysVisible autoPlay={false} animation="slide">
          {gridData.map((item) => (
            <div key={item.name} className={classes.mobileInfoGrid}>
              <Typography align={"center"}>
                <SvgIcon className={classes.icon}>
                  {React.createElement(Icons[item.fileName])}
                </SvgIcon>
              </Typography>
              <CardContent classes={{root:classes.mobileCardContent}}>
                <Typography gutterBottom variant="h5" component="h2">
                  {item.name}
                </Typography>
                <Typography gutterBottom variant="body" component="p">
                  {item.description}
                </Typography>
              </CardContent>
            </div>
          ))}
        </Carousel>
      )}
    </section>
  );
};

export default InfoGrid;
