import * as React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { HeroBanner } from "../components/HeroBanner";
import { ServicesGrid } from "../components/ServicesGrid";
import { InfoGrid } from "../components/InfoGrid";
import { Parallax, ParallaxLayer } from "../components/Parallax/Parallax";
import { Link } from "gatsby";
import Button from "@material-ui/core/Button";
import Seo from "../components/Seo/Seo";
import CallIcon from '@material-ui/icons/Call';

import CleaningImg from "../images/hero-banner/woman_cleaning_dishes.png";
import CleaningImg2x from "../images/hero-banner/woman_cleaning_dishes_2x.jpeg";

import { useStyles } from "../pagesStyles/Home.styling";

const Home = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");
  return (
    <>
      <Seo title="Home" />
      <Parallax>
        <ParallaxLayer padding={0} depth={-2} scale={3}>
          <HeroBanner
            image={desktop ? CleaningImg : CleaningImg2x}
            alt="cleaning stairs"
            heading="Offering a holistic approach with support of a high standard of Housekeeping, Cleaning and care of your home or at the office, tailored to your needs."
          ></HeroBanner>
        </ParallaxLayer>
        <ParallaxLayer depth={-1} scale={2} base padding="90.5vh 0">
          <div style={{ padding: desktop ?"5vh 0":0 }}>
            {desktop ? (
              <Button
                color="primary"
                variant="contained"
                classes={{ root: classes.bannerButton }}
              >
                <Link to="/callback" className={classes.link}>
                  Request Callback
                </Link>
              </Button>
            ) : (
              <a href="tel:01564335999" className={classes.link}>
                <Button
                  color="primary"
                  variant="contained"
                  classes={{ root: classes.bannerButtonMobile }}
                  endIcon={<CallIcon/>}
                >
                  Call Now
                </Button>
              </a>
            )}
          </div>
          <div style={{ backgroundColor: "white" }}>
            <ServicesGrid />
            <InfoGrid />
          </div>
        </ParallaxLayer>
      </Parallax>
    </>
  );
};

export default Home;
