export const gridData = [
  {
    name: "Experienced Providers",
    fileName: "CleanerStaffIcon",
    description: "We are a family run business with years of experience offering high standards of cleaning and care services.",
    link: "",
  },
  {
    name: "Customised Schedules",
    fileName: "CalendarIcon",
    description: "Our cleaners will come at a time to best suit you so not to interfere with your lifestyle using our Cloud Technology systems. We are able to monitor the length of the call agreed by our customers.",
    link: "",
  },
  {
    name: "Advanced Products",
    fileName: "VacuumIcon",
    description: "Whilst cleaning your home we will use your products and equipment to reduce cross contamination, we will be sourcing “Green” cleaning products as much as possible when doing deep/office cleaning.",
    link: "",
  },
  {
    name: "Extra Care Hygiene",
    fileName: "HandClothIcon",
    description: "Our staff will always arrive with appropriate attire, gloves, face masks, aprons and will regularly wash hands during their visit.",
    link: "",
  },

];
